// Imports: Dependencies
import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist"; // Imports: Redux
import ReduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import reducers from "../reducers";

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: "root",
  // Storage Method (React JS)
  storage: storage,
  // Whitelist (Save Specific Reducers)
  whitelist: ["userSession", "postponeReasons", "cancelReasons", "language"],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    "loading",
    "shipment",
    "pendingShipments",
    "shipments",
    "bulkShipment",
    "shipmentPrint",
    "sidebarShow"
  ]
}; // Middleware: Redux Persist Persisted Reducer

const persistedReducer = persistReducer(persistConfig, reducers); // Redux: Store
const store = createStore(
  persistedReducer,
  {},
  composeWithDevTools(applyMiddleware(ReduxThunk))
);

// Middleware: Redux Persist Persister
let persistor = persistStore(store); // Exports
export { store, persistor };
